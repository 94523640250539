<template>
  <form @submit.prevent.stop="save" class="row q-gutter-lg" v-if="formFields">
    <div class="col">
      <div class="q-mb-sm">
        <h6 class="title-h6">{{$t('processes.agent')}}</h6>
        <div class="row">
          <AgentCard class="col-6" icon="accessibility" :showSeparator="false" v-if="linkedAgent" :agent="linkedAgent" @menuClick="onMenuClick" />
          <div class="add-agent-card col-6" v-else>
            <search-box v-if="searchBoxActivated"
              @input="onSelectLinkedObj"
              model_type="agent"
              :label="$t('processes.fields.agent.label')"
              :params="{ 'with_virtual_objects': true, 'with_deleted_objects': true }"
              :color="color" />
            <base-add-button v-else :label="$t('processes.add_agent')" :color="color" @click="searchBoxActivated = true" />
          </div>
        </div>
      </div>
      <div>
        <h6 class="title-h6">{{$t('processes.linked_wallet')}}</h6>
        <div class="row q-col-gutter-md q-mb-md">
          <add-a-wallet :max="1" v-if="currentSuiviDesDelegationsProcess && currentSuiviDesDelegationsProcess.wallets" @walletsUpdated="updateWallet" v-model="currentSuiviDesDelegationsProcess.wallets" :wallets="currentSuiviDesDelegationsProcess.wallets" class="col"/>
          <add-a-wallet :max="1" v-else @walletsUpdated="updateWallet" class="col"/>
        </div>
        <q-card flat bordered class="q-pa-sm q-mb-md collapsible-div">
          <q-card-section class="q-gutter-sm justify-center">
            <div class="row q-gutter-sm">
              <base-input-date class="col" v-bind="formInputProps('date_effet')" :color="color"
                v-model="formData.date_effet" ref="label" />
              <base-input-date class="col" v-bind="formInputProps('date_previsionnelle_fin_pret')" :color="color"
                v-model="formData.date_previsionnelle_fin_pret" ref="label" />
            </div>
          </q-card-section>
        </q-card>
      </div>
      <div class="bloc-item q-mb-sm q-mt-md">
        <h6 class="title-h6">{{$t('processes.banqueDeleguee')}}</h6>
        <div class="toggler-collapse" @click="toggleCollapsible">
          <div class="arrow"></div>
        </div>
        <q-card flat bordered class="q-pa-sm q-mb-md collapsible-div">
          <q-card-section class="q-gutter-sm justify-center">
            <div class="row q-gutter-sm">
              <base-input-text class="col" v-bind="formInputProps('nom_banque')" :color="color"
                v-model="formData.nom_banque" ref="label" />
              <base-input-text class="col" v-bind="formInputProps('adresse_banque')" :color="color"
                v-model="formData.adresse_banque" ref="label" />
            </div>
            <div class="row q-gutter-sm">
              <base-input-text class="col" v-bind="formInputProps('reference_pret')" :color="color"
                v-model="formData.reference_pret" ref="label" />
              <base-input-text class="col" v-bind="formInputProps('montant_pret')" :color="color"
                v-model="formData.montant_pret" ref="label" />
            </div>
          </q-card-section>
        </q-card>
      </div>
      <div class="bloc-item q-mb-sm q-mt-md">
        <h6 class="title-h6">{{$t('processes.courrierAcceptation')}}</h6>
        <div class="toggler-collapse" @click="toggleCollapsible">
          <div class="arrow"></div>
        </div>
        <q-card flat bordered class="q-pa-sm q-mb-md collapsible-div">
          <q-card-section class="justify-center">
            <div class="row">
              <div class="col-6">
                <base-input-date class="col" v-bind="formInputProps('date_envoi_courrier')" :color="color"
                  v-model="formData.date_envoi_courrier" ref="label" />
              </div>
            </div>
          </q-card-section>
        </q-card>
      </div>
      <div class="bloc-item q-mb-sm q-mt-md">
        <h6 class="title-h6">{{$t('processes.dateMainLevee')}}</h6>
        <div class="toggler-collapse" @click="toggleCollapsible">
          <div class="arrow"></div>
        </div>
        <div class="collapsible-div">
          <q-card flat bordered class="q-pa-sm q-mb-md">
            <q-card-section class="justify-center">
              <div class="row">
                <div class="col-6">
                  <base-input-date class="col" v-bind="formInputProps('date_main_levee')" :color="color"
                    v-model="formData.date_main_levee" ref="label" />
                </div>
              </div>
            </q-card-section>
          </q-card>
          <q-card flat bordered class="q-pa-sm q-mt-sm">
            <q-card-section class="justify-center">
              <div class="row">
                <div class="col-6">
                  <q-item-label class="q-pt-sm q-pl-sm">{{$t('processes.fields.commentaires.label')}}</q-item-label>
                  <base-input-text class="q-px-sm q-pb-none row" placeholder="" label="" v-bind="formInputProps('commentaires')" :color="color"
                    v-model="formData.commentaires" ref="label" />
                </div>
              </div>
            </q-card-section>
          </q-card>
        </div>
      </div>
    </div>

    <div class="col col-2 q-gutter-y-sm">
      <q-list class="full-width">
        <q-item class="q-pa-none">
          <base-input-select v-bind="formInputProps('statut')" :color="color"
            v-model="formData.statut" ref="statut" />
      </q-item>
      </q-list>

      <div class="q-gutter-y-sm">
        <q-btn :color="color" class="full-width" size="sm" outline @click.native="destroy" :disabled="!isUpdate">{{$t('form.delete')}}</q-btn>
        <q-btn :color="color" class="full-width" size="sm" unelevated type="submit" :disabled="!hasChanged">{{$t('form.submit')}}</q-btn>
        <q-btn :color="color" class="full-width q-py-md" size="sm" @click="finishProcess">{{$t('form.finish')}}</q-btn>
      </div>

    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
import { BaseForm } from '../../../mixins'
import addAWallet from '../shared/addAWallet'
import { AgentCard } from '../../../components/agents'

export default {
  mixins: [BaseForm],
  components: { addAWallet, AgentCard },
  props: {
    'form_model': { type: String, default: 'processes' },
    'action': { type: String, default: 'processes/saveProcesses' }
  },

  data () {
    return {
      filter: {},
      loading: false,
      isFormOpen: false,
      searchBoxActivated: false,
      isUpdate: this.$route.params.id,
      linkedAgent: null
    }
  },

  computed: {
    ...mapGetters({
      color: 'pages/getColor',
      isAdmin: 'auth/isAdmin',
      currentSuiviDesDelegationsProcess: 'processes/getCurrentProcess',
      rowsPerPageOptions: 'config/getRowsPerPageOptions'
    })
  },

  watch: {
    formFields: {
      handler (newVal, oldVal) {
        this.formData.model_type = 'delegation'
        if (!newVal || !this.currentSuiviDesDelegationsProcess) return

        this.formData = {
          ...this.formData,
          ...newVal.map(({ id, options }) => ({ id, options }))
            .reduce((acc, { id, options }) => ({
              ...acc, [id]: options ? options.find(({ value }) => value === this.currentSuiviDesDelegationsProcess[id]) : this.currentSuiviDesDelegationsProcess[id]
            }), {})
        }

        this.formData.related_objects = []
        if (this.$route.params.id) {
          this.formData.id = this.$route.params.id

          // Set wallets data
          let walletsMapped = this.currentSuiviDesDelegationsProcess.wallets.map(wallet => ({
            model_type: 'wallet',
            model_id: wallet.id
          }))
          this.formData.related_objects.push(...walletsMapped)

          // Set agent
          if (this.currentSuiviDesDelegationsProcess.agents) {
            this.linkedAgent = this.currentSuiviDesDelegationsProcess.agents.map(agent => ({
              ...agent,
              label: agent['TITULAIRE'],
              actions: [{ label: this.$t('tasks.remove_linked_object'), payload: { name: 'model-detach', params: { id: agent.id } } }],
              is_favorite: !!this.favorites && !!this.favorites['agent'] &&
                this.favorites['agent'].some(favorite => favorite.id === agent.id),
              model: { model_type: 'agent', model_id: agent.id }
            }))[0]
          }
        }
      },
      immediate: true
    },
    linkedAgent (newVal, oldVal) {
      if (!this.formData.related_objects) this.formData.related_objects = []
      this.formData.related_objects = this.formData.related_objects.filter(related => related.model_type !== 'agent')
      if (newVal) this.formData.related_objects.push({ model_type: 'agent', model_id: newVal.id })
    }
  },

  mounted () {
    this.$store.commit('pages/setBreadcrumbs', [
      { label: this.$t('global.home'), to: { name: 'home' }, icon: 'home' },
      { label: this.$t('processes.processAgence'), to: { name: '' } },
      { label: this.$t('processes.title.suivi_des_delegations'), to: { name: 'suivi-des-delegations-en-cours' } },
      this.$route.params.id ? { label: `fiche #${this.$route.params.id}`, to: { name: 'suivi-des-delegations-form', params: { id: this.$route.params.id } } } : {}
    ])
    this.$store.commit('pages/setPageTitle', this.$t('processes.title.suivi_des_delegations'))

    this.$store.commit('pages/setTabs', [
      { name: 'suivi-des-delegations-form', label: 'Informations', to: { name: 'suivi-des-delegations-form' }, params: { id: this.$route.params.id } }
    ])
    if (this.$route.params.id) {
      this.formData.id = this.$route.params.id
    }
  },

  preFetch ({ store, currentRoute, previousRoute, redirect, ssrContext }) {
    if (currentRoute.params.id) {
      store.dispatch('processes/fetchProcess', { id: currentRoute.params.id, type: 'delegation' }).then(() => {
        store.dispatch('processes/fetchFormFields', 'delegation')
      })
    } else {
      store.dispatch('processes/fetchFormFields', 'delegation')
    }
  },
  beforeDestroy () {
    this.$store.commit('processes/setCurrentProcess', null)
  },
  methods: {
    finishProcess () {
      this.formData.statut = { label: 'TERMINE', value: 'TERMINE' }
      this.save()
    },
    toggleCollapsible (event) {
      let originalElement = event.target || event.srcElement || event.originalTarget
      if (originalElement.classList.contains('arrow')) {
        originalElement.parentElement.classList.toggle('rotate')
        originalElement.parentElement.nextSibling.classList.toggle('display-none')
      } else {
        originalElement.classList.toggle('rotate')
        originalElement.nextSibling.classList.toggle('display-none')
      }
    },
    onSave ({ id }) {
      this.isUpdate = true
      if (!this.$route.params.id) this.$router.push({ name: 'suivi-des-delegations-form', params: { id } })
    },
    copyCour () {
      const text = 'Ito ny texte'
      let el = document.createElement('textarea')
      el.value = text
      el.setAttribute('readonly', '')
      el.style.position = 'absolute'
      el.style.left = '-9999px'
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)
    },
    updateWallet (wallets) {
      console.log(wallets)
      let walletMapped = wallets.map(wallet => ({
        model_type: 'wallet',
        model_id: wallet.id
      }))

      if (!this.formData.related_objects) this.formData.related_objects = []
      // Remove all wallet in related object
      this.formData.related_objects = this.formData.related_objects.filter(related => related.model_type !== 'wallet')
      this.formData.related_objects.push(...walletMapped)
    },
    onSelectLinkedObj (obj) {
      console.log(obj)
      this.linkedAgent = {
        label: obj.value.label,
        id: obj.value.value,
        actions: [
          { label: this.$t('tasks.remove_linked_object'), payload: { name: 'model-detach', params: { id: obj.value.value } } }
        ]
      }
    },
    onMenuClick ({ name, params }) {
      this.hasChanged = true
      if (name === 'model-detach') {
        this.linkedAgent = null
        this.searchBoxActivated = false
      }
    },

    destroy () {
      this.$confirm(this.$t('processes.delete_process_confirm'), this.$t('form.delete')).onOk(() => {
        this.pending = true
        this.$store.dispatch('processes/deleteProcesses', { type: 'delegation', id: this.$route.params.id }).then(() => {
          this.notifySuccess('processes.delete_success')
          this.$router.go(-1)
        }).catch(this.$notifyError)
          .finally(() => { this.pending = false })
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
  .process-color
    color $process
  .bloc-item
    position relative
  .toggler-collapse
    border 1px solid $process
    border-radius 100%
    width 20px
    height 20px
    position absolute
    right 0;
    top 7px;
    cursor pointer
  .arrow
    border-top 1px solid $process
    border-left 1px solid $process
    transform rotate(45deg)
    width 6px
    height 6px
    margin-left 6px
    margin-top 7px
  .display-none
    display none
  .rotate
    transform rotate(180deg)
</style>
